import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import policiesMainIcon from '../images/policies-main-icon.svg';
import PoliciesContent from '../components/PoliciesContent';
import HeroBannerSubpage from '../components/HeroBannerSubpage';

const Terms = () => (
  <Layout page="terms">
    <HeroBannerSubpage
      title="Terms & Conditions"
      subtitle="Entracker User Terms of Services"
      image={policiesMainIcon}
    />
    <SEO
      title="Terms and Conditions"
      subtitle="Use Entracker to manage your detailing team work"
      keywords={['conditions', 'detailers','project', 'managemnt']}
    />
    <PoliciesContent key="terms" type="terms" />
  </Layout>
)

export default Terms;
